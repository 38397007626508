.stats {
  
  h1 {
    margin-bottom: 40px;
  }

  .par6s {
    &--entry {
      margin-bottom: 5px;
    }
  }
  .text {
    color: #525252;
  }
  .number {
    color: #000;
  }

  .stats-grouping {
    margin-bottom: 40px;
  }

  @media (min-width: 840px) {
    margin-left: 48px;
  }
}
