.admin-seasons {
  display: flex;
  .season-list {
    display: flex;
    flex-direction: column;
    button {
      max-width: 70px;
    }
    ul {
      max-width: 150px;
    }
  }
  .season-content {
    width: 100%;
    .hotel-address {
      margin: 10px;
      max-width: 350px;
    }
    .games {
      .games-entry {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        .game-entry {
          width: 260px;

          .game-entry-container {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}
