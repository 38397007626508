.groupsContainer {
  margin-top: 25px;
  .groups-day {
    margin-bottom: 24px;
      & .day {
        @media (max-width: 500px) {
          display: block;
        }
      }
      & .course {
        margin-left: 10px;
        @media (max-width: 500px) {
          margin-left: 20px;
        }
    }
    .groups-game {
      display: flex;
      justify-content: center;
      gap: 16px 16px;
      @media (max-width: 840px) {
        gap: 8px 8px
      }
      margin-top: 8px;
      .groups-game-turtles {
        //margin-right: 12px;
        width: 140px;
        button {
          width: 100%;
        }
        @media (max-width: 840px) {
          width: 60px;
          //margin-right: 6px;
        }
        & .groups-game-turtle-turtle {
          &.turtle-selected {
            background-color: yellow;
          }
          & .full {
            @media (max-width: 840px) {
              display: none;
            }
          }
          & .mobile {
            @media (min-width: 840px) {
              display: none;
            }
          }
        }
      }
    }
  }
}
