.admin-players {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  .players__container {
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
    margin-top: 8px;
    .actions {
      display: flex;
      flex-direction: column;
      top: 0;
      background-color: white;
      position: sticky;
      z-index: 2;
    }
    .players__list {
      width: 200px;
      .player-status {
        display: inline-block;
        margin-right: 4px;
        padding: 2px;
        width: 12px;
        text-align: center;
        border: 1px solid black;
        background-color: beige;
      }
    }
  }
  .player__details {
    .player__details-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 24px;
      .player__row {
        display: flex;
        flex-direction: row;
        gap: 10px;
       
      }
    }
  }
}
