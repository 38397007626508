.trophy-admin {
  .trophies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    .trophy {
      width: 300px;
    }
  }
}
