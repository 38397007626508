.TurtleList {
    max-width: 500px;

    @media (min-width: 500px) {
        margin-left: 10%;
        margin-right: 10%;
    }

    .list {
        tr {
            height: 35px !important;
        }

        td, th {
            height: 35px !important;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }

        .isActive-icon {
            color: green !important;
        }

        &--legend {
            display: flex;
            justify-content: flex-end;

            &--toggle {
                width: 150px !important;
            }

            .isActive-icon {
                order: 1;
                width: 15px !important;
                height: 15px !important;
            }
        }

        .column {
            &.isActive {
                width: 30px !important;
                padding: 0 !important;
            }

            &.started {
                width: 70px !important;
            }

            &.handicap {
                width: 55px !important;
                text-align: right !important;
            }
        }
    }
}
