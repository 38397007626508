.gameDaySummary {
  max-width: 350px;
  width: 100%;

  @media (min-width: 1000px) {
    margin-top: 30px;
  }
  &--container {
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 5px;

    .joinner {
      margin-left: 2px;
      margin-right: 2px;
      font-size: x-small;
      color: #525252;
  }

  .header {
    .day {
      color: #525252;
      display: inline;
    }
    .course {
      display: inline;
      margin-left: 4px;
    }
  }
    .left-cell {
      min-width: 55px;
      margin-right: 15px;
    }
    .icon-button--icon-top {
      flex-direction: column;
      margin-right: 4px;
      .MuiButton-startIcon {
        margin-left: 0;
        margin-right: 0;
      }
    }

    & .completedGame {
      display: flex;
      flex-direction: row;
      margin-top: 4px;

      & .detailsCell {
        display: flex;
        flex-direction: column;
      }

      & .resultCell {
        text-align: center;

        span {
          margin-bottom: 4px;
          font-size: 34px;
        }
      }
    }

    & .futureGame {
      display: flex;
      flex-direction: row;
      .infoContainer {
        display: flex;
      }
      & .weatherContainer {
        & img {
          width: 45px;
        }
      }
    }
  }
}
