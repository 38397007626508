.seasonScoresGrid {
  .column-header-content {
    cursor: pointer;
  }

  & thead {
    tr {
      height: 38px !important;

      & th {
        height: auto !important;
      }
    }
  }

  & .table-body {
    tr {
      height: 35px !important;
    }

    tr td {
      height: auto !important;
    }
  }

  & .gridSelectors {
    display: none;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 8px;
    font-size: 13px;
    & button {
      & span {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      font-size: 13px;
    }
  }
  .scores-table {
    // width: auto;
  }
  & .grossColumn {
    border-left: 1px solid black;
  }

  & .scoreColumn {
    text-align: center !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    text-overflow: unset !important;
    //width: 40px;
  }

  & .turtleColumn {
    padding-left: 1px !important;
    padding-right: 1px !important;
    width: 160px;

    & .rank {
      width: 15px;
      display: inline-block;
      font-size: 10px;
    }

    & .name {
      width: 120px;
      display: inline-block;
    }

    & .shortname {
      display: none;
    }

    & .handicap {
      width: 25px;
      display: inline-block;
      font-size: 10px;
      text-align: right;
    }
  }

  & .superHeader {
    & th {
      text-align: center !important;
    }
  }

  
  @media (max-width: 1250px) {
    & .gridSelectors {
      display: flex !important;
    }

    & .day.column {
      display: none !important;

      &.important {
        display: table-cell !important;
      }
    }
    th.turtleColumn {
      width: auto;
    }
    & .turtleColumn {
      //already hidden before this size I think
      width: auto;

      // .handicap {
      //   display: none;
      // }
    }
    table.scores-table {
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    & .turtleColumn {
      .shortname {
        display: inline-block;
      }

      .name {
        display: none;
      }
      /*.rank {
                      display: none !important;
                  }*/
    }
  }
}
