
.score-entry {
  min-height: calc(100vh - 64px);
  width: 100%;
  display: flex;
  .main-content {
    padding: 20px;
    min-width: 910px;
    .header {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      .commands {
        button {
          margin-right: 4px;
          margin-left: 4px;
        }
      }
      .info-text {
        color: #525252;
        margin-left: 8px;
        .header {
          &--turtle {
            margin-right: 8px;
            font-size: 28px;
          }
        }
      }
    }
    .scorecard {
      .scorecard-errors {
        .scorecard-errors-error {
          margin-left: 30px;
          color: red;
        }
      }
    }
  }
  .group-content {
    background-color: #f2f2f2;
    box-shadow: rgba(17, 17, 26, 0.1) 3px 3px 3px;
    margin-left: -8px; //To counteract the padding of the main-content
    .group-list {
      width: 300px;
    }
  }
}
