.CourseList {
    @media (min-width: 500px) {
        margin-left: 10%;
        margin-right: 10%;
    }

    .mobile {
        display: none;
    }

    .desktop {
        max-width: 700px;
    }

    th, td {
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 35px !important;
    }

    tr {
        height: 35px !important;
    }

    .column {

        &.map {
            width: 40px !important;
            padding-left: 0 !important;

            button {
                padding-left: 0 !important;
                padding-right: 0 !important;
                width: 35px !important;
                height: 35px !important;
            }
        }

        &.lowest {
            text-overflow: initial !important;
            width: 180px !important;
        }
    }

    @media (max-width: 450px) {
        .mobile {
            display: table;
        }

        .desktop {
            display: none;
        }
    }
}
