.course-details {
    max-width: 1000px;

    @media (min-width: 500px) {
        margin-left: 10%;
        margin-right: 10%;
    }

    .header {
        display: flex;
        flex-wrap: wrap;

        .course {
            margin-right: 5px;
        }
    }

    th, td {
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 35px !important;
    }

    tr {
        height: 35px !important;
    }

    hr {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .history {
        margin-top: 35px;

        .column {
            &.gross-group, &.net-group {
                border-left: 1px solid #2c2c2c;
                padding-left: 7px !important;

                .column {
                    width: 90px;
                }
            }

            &.dayNumber {
                text-align: center !important;
            }
        }
        /*&--body {
            .column {
                &.lowScore {
                    text-align: right !important;
                }
            }
        }*/
        .legend {
            font-size: smaller;
            color: #232323;
        }
    }

    .tees {
        margin-top: 35px;
        max-width: 800px;
    }

    .superHeader {
        & th {
            text-align: center !important;
        }
    }

    .properties {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;

        &--property {
            width: 300px;
            display: flex;
            flex-wrap: wrap;

            &--label {
                font-weight: bold;
                margin-right: 10px;

                &:after {
                    content: ': ';
                }
            }
        }
    }
}
