.group-admin {
  .groups {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .day {
      .day-groups {
        display: flex;
        flex-direction: row;
        .group {
          display: flex;
          flex-direction: column;
          border-right: 1px solid gray;
          padding: 5px;
          .turtle-name {
            width: 140px;
            text-overflow: ellipsis;
          }
          .turtle--selected {
            background-color: rgb(165, 165, 50);
          }
        }
      }
    }
  }
}
