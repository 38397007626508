.GameDetailsPage {
  @media (min-width: 500px) {
    margin-left: 10%;
    margin-right: 10%;
  }

  & .infoContainer {
    &--label {
      font-weight: bolder;
      margin-right: 4px;
    }
    &--data {
      a:last-child {
        margin-left: 8px;
      }
    }
    &--rightAlign {
      display: flex;
      flex-wrap: wrap;
    }
  }

  & .scoreAndMessageContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .sortable {
      cursor: pointer;
    }
    .scoreColumn {
      text-overflow: unset !important;
    }
    th.scoreColumn {
      text-align: right;
    }
    & .scoreContainer {
      flex-grow: 1;
      flex-basis: auto;

      & tr {
        height: 35px !important;
      }

      & .turtleColumn {
        padding-left: 5px !important;
      }

      & .column {
        height: auto !important;
      }
    }

    & .messageContainer {
      flex-grow: 2;
      padding-left: 5px;
      padding-right: 5px;
      width: 400px;
      .new-priority {
        margin-top: 10px;
      }
    }

    @media (max-width: 770px) {
      & .messageContainer {
        width: 300px;
      }
    }
  }

  & .bottomNav {
    display: none !important;
  }

  @media (min-width: 1000px) {
    & .infoContainer {
      margin-left: 100px;
    }
  }

  @media (max-width: 615px) {
    & .bottomNav {
      display: flex !important;
    }

    & .scoreAndMessageContainer {
      & .messageContainer {
        height: 100vh;
      }
    }
  }
}
