.TrophyList {
  @media (min-width: 500px) {
    margin-left: 10%;
    margin-right: 10%;
  }

  .trophy-list {
    display: flex;
    flex-wrap: wrap;

    &--card {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 15px;
      max-width: 460px;
      width: 100%;
      padding-left: 0px !important;
      padding-right: 0px !important;

      @media (min-width: 720px) {
        max-width: 320px;
      }

      &--text {
        height: 120px;
        .notes {
          color: #525252;
        }
      }

      &--header {
        background-color: rgba(157, 157, 157, 0.3) !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
      }

      &--subcard {
        margin-left: 15px;
        margin-right: 15px;
        max-width: 460px;
        width: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;

        &--header {
          div {
            padding-right: 3px !important;
          }
        }
      }

      &--actions {
        display: flex;
        justify-content: center;
      }
    }
  }
}
