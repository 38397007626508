.score-details {
  @media (min-width: 500px) {
    margin-left: 10%;
    margin-right: 10%;
  }

  padding-top: 20px;

  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    &--main {
      .name {
        font-weight: bold;
        font-size: x-large;
      }
    }

    &--tees {
      display: flex;
      flex-wrap: wrap;

      div {
        margin-right: 10px;
      }
    }

    &--score {
      order: -1;
      margin-right: 20px;
      width: 100px;
      height: 90px;
      border: 1px solid black;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-weight: bold;
        font-size: xx-large;
        vertical-align: middle;
      }
    }
    &--group {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 840px)
      {
        justify-content: initial;
      }
      table {
        th {
          font-weight: bold;
        }
        td.score-column {
          text-align: right;
        }
      }
    }
  }

  & .summary {
    display: flex;
    flex-direction: row;

    &--scores {
      display: flex;
      flex-wrap: wrap;

      & div {
        display: flex;
        flex-wrap: wrap;
        margin-right: 10px;
      }

      &--label {
        font-weight: bold;
      }
    }

    &--holes {
      display: flex;
      flex-wrap: wrap;
      margin-right: 5px;

      & div {
        display: flex;
        margin-right: 10px;
      }

      &--label {
        width: 110px;
        font-weight: bold;
      }
    }
  }

  & .score-card {
    max-width: 1500px;

    .mobile {
      display: none;
    }

    @media (max-width: 840px) {
      .mobile {
        display: table;

        thead {
          tr {
            th {
            }
          }
        }
      }

      .desktop {
        display: none;
      }
    }
    .total-cell {
      background-color: #c2bfbf;
    }
    .slim-row {
      td {
        span {
          padding: 0;
        }
      }
    }
    td,
    th {
      padding-left: 4px !important;
      padding-right: 4px !important;
      text-align: center !important;
      height: auto;
      //height: 40px !important;
    }

    tr {
      //height: 40px !important;
      height: 10px;
      &.subtotal,
      &.total {
        background-color: #c2bfbf;
        //height: 30px !important;

        td {
          //      height: 30px !important;
        }
      }
    }

    .column.back9 {
      /*display: none;*/
    }

    .subtotal {
      font-weight: 600;
    }

    .total {
      font-weight: 700;
    }

    .legend {
      // display: flex;
      flex-wrap: wrap;
      // margin-bottom: 5px;
      margin-top: 8px;
      gap: 8px 8px;

      &.score-marker-container {
        // justify-content: flex-start !important;
      }

      &--item {
        display: flex;
        height: 15px;
        margin-right: 15px;
        align-items: center;
        margin-top: 5px;

        .swatch {
          width: 16px !important;
          height: 18px !important;
          margin-left: 4px;

          div {
            margin-top: 1px;
          }
        }
      }
    }

    .score-marker-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .score-marker {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.double-par {
          border: 5px solid #7c0707;
        }

        &.double-bogey {
          border: 2px solid #7c0707;

          div {
            border: 2px solid #7c0707;
            height: 78%;
            width: 78%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &.bogey {
          border: 2px solid #7c0707;
        }

        &.par {
          border-bottom: 2px solid green;
        }

        &.birdie {
          border: 2px solid green;
          border-radius: 50%;
        }

        &.eagle {
          border: 2px solid green;
          border-radius: 50%;

          div {
            border: 2px solid green;
            border-radius: 50%;
            height: 78%;
            width: 78%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
