.score-card {
  table {
    border-collapse: collapse;
    thead {
      background-color: rgb(214, 214, 214);
      tr:first-child {
        font-weight: bold;
      }
      td {
        padding: 4px;
        &:first-child {
          padding-right: 15px;
        }
      }
    }
    .legend.column {
      background-color: white;
      border-right: 1px solid rgb(100, 100, 100);
      font-weight: bold;
    }
    tbody {
      .Mui-disabled {
        &.fir,
        &.gir {
          &.checked {
            svg {
              fill: green;
            }
          }
        }
      }
      .name-edit {
        display: flex;
        justify-content: space-between;
      }
      .MuiCheckbox-root {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
    .score-entry--check {
      color: green;
    }
    .speech--active {
      color: green;
    }
    .text-field {
      input {
        width: 20px;
        padding: 2px 4px;
      }
    }
  }
}
