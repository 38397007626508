.TrophyDetailsPage {
    .table {
        .column {
            padding-left: 5px !important;
            padding-right: 5px !important;

            &.year {
                width: 50px;
                text-overflow: initial !important;
            }
            &.name {
                width: 120px !important;
            }
            &.notes {
                text-overflow: initial !important;
                white-space: normal !important;
            }
        }
    }
}
