body {
  //background-color: #eaeaea;
  font-family: "Roboto";
}

.home {
  @media (min-width: 500px) {
    margin-left: 10%;
    margin-right: 10%;
  }
}
#menu-appbar {
  color: rgb(25, 118, 210);
  svg {
    fill: rgb(10, 57, 104);
    margin-right: 4px;
  }
  a,
  a:visited,
  a:active {
    color: rgb(25, 118, 210);
  }
}
.main-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  header {
    flex: 0 1 auto;
  }
  .main-content {
    flex: 1 1 auto;
  }
  .nav-menu {
    color: #fff;
    .icon-button {
      display: flex;
      flex-direction: column;
      line-height: inherit;
    }

    a,
    a:visited,
    a:active {
      color: #fff;
      margin-left: 8px;
    }
  }
  // & .nav-menu {
  //   box-shadow: rgba(0, 0, 0, 0.56) 0px 0px 10px 0px;
  //   & .navBar {
  //     background: rgb(25, 118, 210);
  //     /*display: flex;
  //           justify-content: space-between;*/
  //     ul {
  //       margin: 0;
  //       margin-top: 10px;
  //     }

  //     &--logo {
  //       font-size: 25px;
  //       margin-left: 20px;
  //     }

  //     &--main-nav {
  //       display: none;
  //       list-style-type: none;
  //       margin-top: 20px;
  //       padding-bottom: 10px;
  //     }

  //     & .active {
  //       display: block;
  //     }

  //     &--toggle {
  //       position: absolute;
  //       top: 10px;
  //       right: 20px;
  //       cursor: pointer;
  //       color: rgba(255, 255, 255, 0.8);
  //       font-size: 24px;
  //       background-color: transparent;
  //       background-image: none;
  //       border: 1px solid transparent;
  //       border-radius: 4px;

  //       & .icon-bar {
  //         display: block;
  //         width: 22px;
  //         height: 2px;
  //         border-radius: 1px;
  //         background-color: #888;
  //       }

  //       & .icon-bar + .icon-bar {
  //         margin-top: 4px;
  //       }
  //     }

  //     & li {
  //       display: block;
  //       margin-left: auto;
  //       margin-right: 20px;
  //       margin-top: 5px;
  //       margin-bottom: 5px;
  //       text-align: right;
  //       svg {
  //           vertical-align: bottom;
  //       }
  //     }
  //     & a {
  //       display: block;
  //       text-decoration: none;
  //       color: #fff;

  //       svg {
  //         height: 18px !important;
  //         width: 18px !important;
  //         color: inherit !important;
  //       }

  //       &:hover {
  //         background-color: #000;
  //       }
  //     }
  //   }
  // }

  @media screen and (min-width: 768px) {
    .nav-menu {
      & .navBar {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
        height: 70px;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;

        &--toggle {
          display: none;
        }

        & li {
          margin-right: 20px;
        }

        &--main-nav {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-right: 30px;
        }
      }
    }
  }

  & .main-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

main {
  padding-top: 100px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 25px;
 // min-height: 69vh;

  &.no--padding {
    padding: 0px;
    margin-bottom: 0px;
  }

  .main__wrapper {
    margin-top: 30px;
  }
}

.seasonPage {
  .yearHeader {
    display: flex;
    flex-wrap: wrap;

    .yearContainer {
      margin-right: 10px;
    }
  }
}

.gameDaySummaryContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (min-width: 1000px) {
    margin-top: -30px;
  }
}


@media (max-width: 1300px) {
  .seasonScoresGrid {
    & .turtleColumn {
      //width: 80px !important;

      .shortname {
        display: inline-block;
      }

      .name {
        display: none;
      }

      .handicap {
        display: none;
      }
    }
  }
}

@media (max-width: 700px) {
  /*& .column.bAndB {
        display: none;

        &.important {
            display: table-cell;
        }
    }*/
}


//==================== Animations ====================//
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #0094ff; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s linear infinite;

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--large {
      height: 100vh;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
