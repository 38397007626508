
.TurtlePage {
    h4 {
        color: rgb(109, 109, 109);
        font-size: 1.2rem;
        margin-bottom: 8px;
    }
    @media (min-width: 500px) {
        margin-left: 10%;
        margin-right: 10%;
    }

    &--data {
        div {
            margin-left:15px;
        }
    }
    .closestToIcon {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .seasonGrid {
        margin-top: 24px;
    }
    &--label {
        font-weight: bolder;
    }

    &--rightAlign {
        display: flex;
        flex-wrap: wrap;
    }

    .no-content {
        font-style: italic;
        margin-left: 30px;
        margin-bottom: 20px;
    }

    & .careerStatsContainer {
        display: flex;
        flex-wrap: wrap;

        &--section {
            display: flex;
            flex-wrap: wrap;
            margin-right: 15px;
            margin-bottom: 5px;
            min-width: 145px;

            & .TurtlePage--label {
                margin-right: 3px;
            }
        }
    }

    & .scoreContainer {
        flex-grow: 1;
        flex-basis: auto;
        margin-bottom: 10px;
        .season-select {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
        }
        & .position-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            & div {
                margin-right: 15px;
                margin-top: 6px;
            }
        }

        & .scoreTable {
            max-width: 1000px;
            margin-top: 16px;
            .stat-toggle-buttons {
                display: none;
            }

            .column-header-content {
                /*overflow-wrap: break-word;
                word-break: break-word;*/
                white-space: normal;

                & .short-header {
                    display: none;
                }

                @media (max-width: 350px) {
                    & .short-header {
                        display: inline;
                    }

                    & .long-header {
                        display: none;
                    }
                }
            }

            & .statsColumn {
                text-overflow: unset !important;
                overflow: unset !important;

                &--important {
                    display: table-cell !important;
                }

                &--unimportant {
                    display: table-cell !important;
                }
            }

            & .nonStatsColumn {
                &--important {
                    display: table-cell !important;
                }

                &--unimportant {
                    display: table-cell !important;
                }
            }

            & td {
                padding-left: 1px !important;
                padding-right: 5px !important;
            }

            & th {
                padding-left: 1px !important;
                padding-right: 5px !important;
            }

            & tr {
                height: 35px !important;
            }

            & .turtleColumn {
                padding-left: 5px !important;
            }

            & .column {
                height: auto !important;
            }
        }
    }

    & .closestToContainer {
        margin-top: 40px;
        &--winlist {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px;
            .TurtlePage--data {
                div {
                    margin-left: 4px;
                }
            }
        }
    }

    & .trophyContainer {
        margin-top: 40px;

        &--section {
            & .trophy-data {
                display: flex;
                flex-wrap: wrap;

                & .trophy-notes {
                    margin-left: 5px;
                    font-weight: normal;
                }
            }
        }
    }

    & .bottomNav {
        display: none !important;
    }

    @media (min-width: 1000px) {
        & .infoContainer {
            margin-left: 100px;
        }
    }

    @media (max-width: 615px) {
        & .bottomNav {
            display: flex !important;
        }

        & .scoreContainer {
            height: 100vh;

            & .scoreTable {
                .stat-toggle-buttons {
                    display: block;
                }

                & .statsColumn {

                    &--unimportant {
                        display: none !important;
                    }
                }

                & .nonStatsColumn {
                    &--unimportant {
                        display: none !important;
                    }
                }
            }
        }
    }
}
