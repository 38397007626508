.group-list {
  a,
  a:visited,
  a:active {
    color: rgb(0, 102, 204);
    text-decoration: none;
  }
  .even {
    background-color: rgb(251, 251, 229);
  }
  .score-complete {
    opacity: 0.5;
    font-size: 0.7rem;
  }
  .turtle-not-played {
    text-decoration: line-through;
  }
  .group-number-cell {
    padding: 6px 6px;
  }
}
